// Fonts
$primary-font: "Figtree", sans-serif;
$secondary-font: "BIZ UDPMincho", serif;

// Colors
$white: #fff;
$black: #000;
$dark-grey: darken(#5a5a5a, 8%);
$brand: #532f64;
$green: #679146;
$yellow: #e5b53a;
$yellow-faded: #f8ebcb;
$purple: #850c70;
$brown: #9b5f0e;
$blue: #0096d6;
$teal: #00827e;

$enable-gradients: true;
