@import "upstairs/static_src/styles/_variables";
@import "upstairs/static_src/styles/_placeholders";
@import "upstairs/static_src/styles/_mixins";

.search-form {
  .search-btn {
    right: .25rem;
    top: .2rem;
    bottom: .2rem;
    font-size: .8rem;
  }

  &__input {
    font-size: .8rem;

    @include mediaQuery($max, $xl) {
      font-size: 16px;
    }
  }
}

.search-results__form input {
  font-size: 1.75rem;
}
