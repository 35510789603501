@import "upstairs/static_src/styles/_placeholders";
@import "upstairs/static_src/styles/_mixins";
@import "upstairs/static_src/styles/_variables";

#search-live-results {
  position: absolute;
  z-index: 2;
  right: 0;
  top: 50px;
  width: 100%;
  display: none;
  background: $black;
  color: white;
  font-size: .9rem;
  max-height: 65vh;
  overflow-y: auto;

  .list-group-item {
    background: transparent;
    color: fade-out($white, .25);
    line-height: 1;
    border-bottom: 1px solid fade-out($white, .75);

    &:hover {
      color: $white;
    }
  }

  @include mediaQuery($max, $lg) {
    max-width: 50vw;
  }

  @include mediaQuery($max, $md) {
    max-width: 100vw;
  }
}
