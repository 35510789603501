// ============================
// Font Size Mixins
// ============================

@mixin font-size($fontSize, $lineHeight) {
  font-size: $fontSize;
  line-height: $lineHeight;

  @include mediaQuery($max, $lg) {
    font-size: $fontSize - 0.75rem;
    line-height: $lineHeight - 0.75rem;
  }

  @include mediaQuery($max, $md) {
    font-size: $fontSize - 1.25rem;
    line-height: $lineHeight - 1.25rem;
  }
}

// ============================
// Display Mixins
// ============================

@mixin hover-none {
  @media only screen and (hover: none) {
    @content;
  }
}

@mixin hover {
  @media not all and (hover: none) {
    @content;
  }
}

// ============================
// Media Query Mixins
// ============================

$bpIncrement: 0.01px;

$xxl: 1400px;
$xl: 1200px;
$lg: 992px;
$md: 768px;
$sm: 576px;

$min: min-width;
$max: max-width;

@mixin mediaQuery($constraint, $viewport1, $viewport2: null) {
  @if $constraint == $min {
    @media screen and ($min: $viewport1) {
      @content;
    }
  } @else if $constraint == $max {
    @media screen and ($max: $viewport1 - $bpIncrement) {
      @content;
    }
  } @else {
    @media screen and ($min: $viewport1) and ($max: $viewport2 - $bpIncrement) {
      @content;
    }
  }
}
